// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:8d999fa6-b2dc-48e7-8773-4819eb9c3d17",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_DZDIfBkyv",
    "aws_user_pools_web_client_id": "va7b465jkjc7t2ooiumokf2ud",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://ed0jum94ta.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://my6zfwrg5fg23pszg34pwr7i54.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "assetrac-overlay-storage210531-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
